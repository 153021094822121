import {
  AnalyticsConfig,
  AnalyticsDataLayer,
  AnalyticsEventDataLayer,
  AnalyticsEventPayload,
  ErrorData,
  ErrorForAnalytics,
} from '@abstractTypes/analytics'
import { Store } from '@abstractTypes/graphqlTypes'
import config from '@config/index'
import { useFrameAdvisorUserProfile } from '@hooks/useFrameAdvisorUserProfile'
import { useStoreContext } from '@hooks/useStoreContext'
import {
  formatCommonData,
  formatError,
  formatFrameAdvisorBaseData,
  formatFrameAdvisorPageData,
  formatSessionId,
} from '@libs/analytics'
import { executeOnce, getAnalyticsScriptSrc, useRefValue } from '@libs/utils'
import { FrameAdvisorState } from '@reducers/frameAdvisor'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

declare global {
  interface Window {
    utag_data: AnalyticsDataLayer
    tealium_data2track: (AnalyticsEventDataLayer | ErrorForAnalytics)[]
  }
}

export const loadInitialDataLayer = (
  analyticsConfig: AnalyticsConfig,
  store: Store,
  frameAdvisorState: FrameAdvisorState,
  sessionId: string,
  version: string
) => {
  window.utag_data = {
    ...formatSessionId(sessionId),
    ...formatCommonData(analyticsConfig, store, version),
    ...formatFrameAdvisorBaseData(frameAdvisorState),
  }
}

export const pushToDataTrack = (dataLayer: AnalyticsEventDataLayer | ErrorForAnalytics) => {
  window.tealium_data2track = window.tealium_data2track || []
  window.tealium_data2track.push(dataLayer)
}

export const loadAnalyticScript = (
  analyticsConfig: AnalyticsConfig,
  store: Store,
  frameAdvisorState: FrameAdvisorState,
  sessionId: string,
  version: string
) => {
  const { gtagId, profile, isEnabled } = analyticsConfig
  if (!isEnabled) return

  loadInitialDataLayer(analyticsConfig, store, frameAdvisorState, sessionId, version)

  const headFragment = document.createDocumentFragment()
  const gtag = document.createElement('script')

  gtag.id = gtagId
  gtag.text = `(function(a,b,c,d){
    a='${getAnalyticsScriptSrc(profile, sessionId)}';
    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
    a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
    })();
    `

  headFragment.appendChild(gtag)
  document.head.appendChild(headFragment)
}

export const useLoadAnalyticsScript = (
  analyticsConfig: AnalyticsConfig,
  frameAdvisorState: FrameAdvisorState,
  sessionId: string,
  version: string
) => {
  const store = useStoreContext()

  useEffect(() => {
    if (frameAdvisorState && sessionId) {
      executeOnce(loadAnalyticScript, analyticsConfig.gtagId)(
        analyticsConfig,
        store,
        frameAdvisorState,
        sessionId,
        version
      )
    }
  }, [analyticsConfig, frameAdvisorState, sessionId, store, version])
}

export const trackAnalyticsError = (error: ErrorData) => {
  pushToDataTrack(formatError(error))
}

export const defaultAnalyticsContext = {
  sendAnalyticsEvent: (
    _payload: AnalyticsEventPayload,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _addCommonData = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _isEventWithCatalogProducts = false
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
}

export const AnalyticsContext = React.createContext(defaultAnalyticsContext)

export const AnalyticsProvider: React.FC<{ analyticsConfig: AnalyticsConfig }> = ({
  analyticsConfig,
  children,
}) => {
  const frameAdvisorState = useSelector(state => state.frameAdvisor)
  const sessionId = useSelector(state => state.session.token)

  const store = useStoreContext()
  const userProfileData = useFrameAdvisorUserProfile()

  const storeRef = useRefValue(store)
  const faStateRef = useRefValue(frameAdvisorState)
  const faProfileRef = useRefValue(userProfileData)

  useLoadAnalyticsScript(analyticsConfig, frameAdvisorState, sessionId, config.version.toString())

  const sendAnalyticsEvent = useCallback(
    (payload: AnalyticsEventPayload, addCommonData = false, isEventWithCatalogProducts = false) => {
      // We need to wait one tick to generate the object as we must
      // wait for all ref to be updated on some edge cases
      setTimeout(() => {
        const store = storeRef.current
        if (!analyticsConfig.isEnabled) return

        const newDataLayer: AnalyticsEventDataLayer = addCommonData
          ? {
              ...formatSessionId(sessionId),
              ...formatCommonData(
                analyticsConfig,
                store,
                config.version.toString(),
                isEventWithCatalogProducts,
                payload
              ),
              ...formatFrameAdvisorBaseData(faStateRef.current),
              ...formatFrameAdvisorPageData(faStateRef.current, faProfileRef.current),
              ...payload,
            }
          : {
              ...formatSessionId(sessionId),
              ...payload,
            }
        pushToDataTrack(newDataLayer)
      }, 0)
    },
    [analyticsConfig, faProfileRef, faStateRef, sessionId, storeRef]
  )

  // we need to memoize the context value to avoid sending
  // multiple events in useAnalyticsEvent
  const analyticsContextValue = useMemo(() => ({ sendAnalyticsEvent }), [sendAnalyticsEvent])

  return (
    <AnalyticsContext.Provider value={analyticsContextValue}>{children}</AnalyticsContext.Provider>
  )
}
