import { ContentV2ElectronicsTab } from '@abstractTypes/graphqlTypes'
import { removeNonAlphabetical } from '@libs/arabic'
import { pushToDataTrack } from '@providers/analyticsProvider'

export const usePushToDataTrack =
  () => (selectedTab: ContentV2ElectronicsTab | null | undefined) => {
    const tabName = selectedTab?.tabName || ''
    const elementIdFormatted = `MoreInfo_${removeNonAlphabetical(tabName)}`
    pushToDataTrack({
      id: 'Click',
      data_element_id: elementIdFormatted,
    })
  }
