import {
  ContentV2Electronics,
  ContentV2ElectronicsTab,
  ContentV2Video,
} from '@abstractTypes/graphqlTypes'
import { LinkTypography } from '@components/core/Typography'
import { useAvailableElectronicsProducts } from '@hooks/useAvailableElectronicsProducts'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import { useScrollUpWithBaseContainer } from '@hooks/useScrollUp'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { equals } from '@libs/caseInsensitive'
import { getElectronicsTabs } from '@libs/electronics'
import React, { useCallback, useMemo, useState } from 'react'
import { ElectronicsHeader } from '../ElectronicsHeader'
import { ElectronicsLogo } from '../ElectronicsLogo'
import { ElectronicsPrivacyPolicyTooltip } from '../ElectronicsPrivacyPolicyTooltip'
import ElectronicsSlider from '../ElectronicsSlider'
import { ElectronicsTogglerModel } from '../ElectronicsTogglerModel'
import { ProductTabs } from '../ProductTabs'
import { SeeInVideoModal } from '../SeeInVideoModal'
import {
  ElectronicsTabsContentContainer,
  ElectronicsTogglerAltNavContainer,
  ScrollableContent,
  SeeOnVideoButton,
  SlideCTA,
  SlideTitle,
  StyledElectronicsContent,
  StyledLink,
  StyledLinkWrapper,
  TopContent,
} from './styles'
import { ElectronicsContentProps } from './types'

const setDefaultModel = (models: ContentV2Electronics[], defaultModel?: string) => {
  if (!defaultModel) {
    return models[0]
  }
  return models.find(model => model.product === defaultModel) || models[0]
}

export const ElectronicsContent: React.FC<ElectronicsContentProps> = ({
  isLandingPage,
  electronicsType,
  models,
  products,
  defaultModel,
  onTabChange = () => null,
  analytics,
  shopLinkText,
  seeInActionText,
  shopLinkDisabled = false,
  spiner,
  shopLinkVisible = true,
  modalAnimation = false,
  className,
  sortTabsEnabled = false,
  customTabs,
  initialTabId,
}) => {
  const rtlDirection = useLanguageDirection()
  const [selectedModel, setModel] = useState(setDefaultModel(models, defaultModel))
  const electronicsTabs = useMemo(() => {
    return getElectronicsTabs({ tabs: selectedModel.tabs, sortTabsEnabled, customTabs })
  }, [customTabs, selectedModel.tabs, sortTabsEnabled])
  const initialTabIndex = electronicsTabs.findIndex(
    tab => tab.type && equals(tab.type, initialTabId)
  )
  const tabsLength = (electronicsTabs?.length ?? 0) - 1
  const initialTab = initialTabIndex > -1 ? initialTabIndex : 0
  const [isSeeVideo, setIsSeeVideo] = useState(false)
  const [tabIndex, setTabIndex] = useState(initialTab)
  // for changing slider index only on tab click for smooth swipe behaviour in loop
  const [sliderTabIndex, setSliderTabIndex] = useState(initialTab)
  const [loading, setLoading] = useState(false)
  const availableProducts = useAvailableElectronicsProducts(models, products)
  const selectedProduct = availableProducts[selectedModel.id]
  const { isAlternativeNavigation, zoomLevel } = useNavigationContext()

  const block = !isAlternativeNavigation || zoomLevel !== 200 || !isLandingPage
  const { isScrollUp } = useScrollUpWithBaseContainer({ block, initialValue: false })

  const { basePath } = useStoreIndentity()
  window.history.replaceState({}, document.title)

  const showLoading = useCallback(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])
  const getFixedTabIndexAnalytics = (
    tabIndex: number,
    tabs: ContentV2ElectronicsTab[],
    isLandingPage?: boolean
  ) => {
    const adjustedTabIndex = tabIndex === 0 ? tabs.length : tabIndex > 1 ? tabIndex - 1 : tabIndex
    return tabs[isLandingPage ? tabIndex + 1 : adjustedTabIndex - 1]
  }
  const tabChangeHandler = useCallback(
    (tabIndex: number) => {
      setTabIndex(tabIndex)
      setSliderTabIndex(tabIndex)
      const selectedTab = getFixedTabIndexAnalytics(
        tabIndex,
        selectedModel?.tabs || [],
        isLandingPage
      )
      onTabChange(selectedTab)
    },
    [isLandingPage, onTabChange, selectedModel?.tabs]
  )

  const onModelChange = useCallback(
    (modelId: string) => {
      const model = models.find(m => m?.id === modelId)
      if (model && model !== selectedModel) {
        showLoading()
        setModel(model)
        window.history.replaceState(null, '', model.product)
      }
    },
    [models, selectedModel, showLoading]
  )

  const toggleSeeVideo = () => {
    setIsSeeVideo(c => !c)
  }

  const activeTab = electronicsTabs[tabIndex]
  const seeOnVideo = activeTab?.content?.find(
    (c): c is ContentV2Video => c?.__typename === 'ContentV2Video'
  )

  const isTabsExpanded = !!activeTab?.isShortMedia
  const showSlider = !(
    isAlternativeNavigation &&
    zoomLevel >= 100 &&
    (activeTab.type === 'specifications' ||
      activeTab.type === 'details' ||
      activeTab.type === 'comparison')
  )

  return (
    <>
      <ElectronicsHeader isSticky={isScrollUp}>
        <ElectronicsPrivacyPolicyTooltip />
        <ElectronicsLogo electronicsType={electronicsType} />
        {isAlternativeNavigation && isLandingPage && (
          <ElectronicsTogglerAltNavContainer>
            <ElectronicsTogglerModel
              onModelChange={onModelChange}
              models={models}
              selectedModel={selectedModel}
            />
          </ElectronicsTogglerAltNavContainer>
        )}
      </ElectronicsHeader>
      <LinkTypography href="" aria-labelledby="electronics-slider" />
      <StyledElectronicsContent className={className}>
        <TopContent id="electronics-slider">
          {!isAlternativeNavigation && (
            <ElectronicsTogglerModel
              onModelChange={onModelChange}
              models={models}
              selectedModel={selectedModel}
            />
          )}
          {!isTabsExpanded && !isAlternativeNavigation && (
            <ScrollableContent>
              <SlideTitle
                aria-label={electronicsTabs[tabIndex]?.title || ''}
                aria-description={electronicsTabs[tabIndex]?.title || ''}
              >
                {electronicsTabs[tabIndex]?.title}
              </SlideTitle>
            </ScrollableContent>
          )}
        </TopContent>
        {showSlider && (
          <ElectronicsSlider
            tabs={electronicsTabs}
            key={selectedModel.id}
            onSwipe={i => {
              const nextIndex = rtlDirection ? tabsLength - i : i
              if (nextIndex !== tabIndex) {
                setTabIndex(nextIndex)
                setSliderTabIndex(nextIndex)
              }
            }}
            spiner={spiner}
            isTabsExpanded={isTabsExpanded}
            slideIndex={rtlDirection ? tabsLength - sliderTabIndex : sliderTabIndex}
            updating={loading}
          />
        )}
        <StyledLinkWrapper isTabsExpanded={isTabsExpanded} data-element-id={'Prod_SeeInAction'}>
          {seeOnVideo && (
            <SeeOnVideoButton onClick={toggleSeeVideo} outline title={seeInActionText}>
              {seeInActionText}
            </SeeOnVideoButton>
          )}
          {shopLinkVisible && (
            <StyledLink
              to={selectedProduct?.url && `${basePath}/${selectedProduct?.url}`}
              data-element-id={analytics?.dataElementId}
              data-test={analytics?.dataElementId}
              data-description={analytics?.dataDescription(selectedProduct)}
              aria-label={shopLinkText || ''}
              title={shopLinkText}
            >
              <SlideCTA
                disabled={!selectedProduct?.url || shopLinkDisabled}
                aria-hidden="true"
                aria-disabled="true"
                tabIndex={-1}
              >
                {shopLinkText}
              </SlideCTA>
            </StyledLink>
          )}
        </StyledLinkWrapper>
        <ElectronicsTabsContentContainer>
          <ProductTabs
            tabs={electronicsTabs}
            isTabsExpanded={isTabsExpanded}
            tabIndex={tabIndex}
            tabChangeHandler={tabChangeHandler}
            product={selectedProduct}
            sliderShowed={showSlider}
            isTabsListSticky={isScrollUp}
            seeOnVideo={!!seeOnVideo}
          />
        </ElectronicsTabsContentContainer>
        {seeOnVideo && (
          <SeeInVideoModal
            visible={isSeeVideo}
            onClose={toggleSeeVideo}
            video={seeOnVideo}
            animation={modalAnimation}
          />
        )}
      </StyledElectronicsContent>
    </>
  )
}
