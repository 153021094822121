import { NuanceTabContent } from '@abstractTypes/graphqlTypes'
import { CustomNuanceTabNames, customTabDetailsName, NuanceTab } from '@abstractTypes/nuance'
import { StandardProduct } from '@abstractTypes/product'
import { Tabs } from '@components/Tabs'
import { ScrollableTabContent } from '@components/Tabs/ScrollableTabContent'
import { NuanceHeading4 } from '@components/core/Typography/NuanceTypography'
import { isNuanceCustomTab } from '@libs/nuance'
import { useCallback } from 'react'
import { DetailsContent } from './CustomTabs/DetailsContent'
import HtmlTab from './HtmlTab'
import { ProductTabsContainer, TabContentStyled, TabInnerContent } from './styles'
import { NuanceTabsProps } from './types'

const renderCustomTabContent = (tabName: CustomNuanceTabNames, product?: StandardProduct) => {
  switch (tabName) {
    case customTabDetailsName:
      return product && <DetailsContent product={product} />
    default:
      return null
  }
}

const renderCMSTabContent = (content?: NuanceTabContent['data'], tabId?: string) => {
  switch (content?.__typename) {
    case 'DataNuance':
      return <HtmlTab key={tabId} data={content} />
    default:
      return null
  }
}

export const NuanceTabs: React.FC<NuanceTabsProps> = ({
  tabs,
  isTabsExpanded,
  tabIndex,
  tabChangeHandler,
  isTabsListSticky,
  product,
  showSlider,
}) => {
  const renderContent = useCallback(
    (tab: NuanceTab) => {
      if (isNuanceCustomTab(tab)) return renderCustomTabContent(tab.name, product)

      return renderCMSTabContent(tab.data, tab.id)
    },
    [product]
  )

  return (
    <ProductTabsContainer
      isExpanded={isTabsExpanded}
      isTabsListSticky={isTabsListSticky}
      sliderShowed={showSlider}
    >
      <Tabs
        defaultActiveKey={0}
        activeKey={tabIndex}
        onTabClick={tabChangeHandler}
        scrollLogicalPosition="end"
        tabs={tabs.map((t, i) => ({
          id: t.id ?? '',
          tabContent: () => {
            const isActiveTab = i === tabIndex
            return (
              <NuanceHeading4 weight={isActiveTab ? 'bold' : 'regular'} color="primaryLight">
                {t.label}
              </NuanceHeading4>
            )
          },
        }))}
        dataCalls={'0'}
      >
        {tabs.map((tab, i) => {
          const isActiveTab = i === tabIndex
          return (
            <TabContentStyled key={`${tab.id}-${i}-${tab.title}`}>
              <ScrollableTabContent>
                <TabInnerContent
                  aria-disabled={!isActiveTab}
                  aria-hidden={!isActiveTab}
                  tabIndex={-1}
                  aria-live="polite"
                  id={`tab-content-${tab.id}`}
                >
                  {renderContent(tab)}
                </TabInnerContent>
              </ScrollableTabContent>
            </TabContentStyled>
          )
        })}
      </Tabs>
    </ProductTabsContainer>
  )
}
