import { Device } from '@abstractTypes/utils'
import { Spin } from '@components/Loading'
import { PrimaryButton } from '@components/core/Button'
import { NuanceButtonTypography } from '@components/core/Typography/NuanceTypography'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  transformTranslateX,
} from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import { scrollbar } from '@styles/common'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledSpinner = styled(Spin)`
  margin: auto;
  background-color: ${({ theme }) => theme.nuance.colors.primaryLight};
`

export const StyledNuanceContent = styled.div`
  ${scrollbar}
  display: flex;
  flex-direction: column;
  block-size: 100%;
  position: relative;
  ${landscape(`
    justify-content: flex-end;
    block-size: 100vh;
  `)}
  ${altNavigation(`
    block-size: auto;
    overflow: scroll;
    margin-inline-end: ${pxToRem(54)};
    padding-inline-end: ${pxToRem(5)};
    ::-webkit-scrollbar-track {
      margin-block-start: ${pxToRem(140)};
      margin-block-end: ${pxToRem(100)};
    }
  `)}

  ${altNavigationZoom(
    [150, 175, 200],
    `
    overflow: unset;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
      display: none !important;
    }
  `
  )}
`

export const NuanceTabsContentContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.nuance.colors.primaryLight};
  ${landscape(`
    position: static;
  `)}
`

export const NuanceCTA = styled(NuanceButtonTypography.withComponent(PrimaryButton))<{
  color?: string
  background?: string
}>`
  inline-size: fit-content;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  background-color: ${({ theme, background }) => background ?? theme.nuance.colors.secondary};
  color: ${({ theme, color }) => color ?? theme.nuance.colors.primaryLight};
  padding-inline: ${pxToRem(24)};
  padding-block: ${pxToRem(12)};
  border-radius: ${pxToRem(16)};
  text-wrap: nowrap;
  text-transform: none;
`

export const StyledLink = styled(({ to, ...props }) => <Link to={to} {...props} />)`
  flex: 1;
  ${altNavigation(`
    flex: 0;
  `)}
`

export const StyledLinkWrapper = styled.div<{ isTabsExpanded?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: absolute;
  z-index: 2;
  inset-block-end: ${({ isTabsExpanded }) => (isTabsExpanded ? pxToRem(1486) : pxToRem(729))};
  inset-inline-start: 50%;
  ${transformTranslateX({ ltr: '-50%', rtl: '50%' })}
  transition: top 0.8s;

  /* top changed to bottom as relative parent is changed */
  ${landscape(`
    inset-inline-start: 45vh;
    inset-block-end: 16vh;
    inset-block-start: auto;
    max-inline-size: calc(90vh - ${pxToRem(32)});
  `)}
  ${({ isTabsExpanded }) =>
    tablet(`
      inset-block-start: ${isTabsExpanded ? clampValues(270, 360) : clampValues(562, 830)};
      inset-block-end: unset;
  `)}
  ${landscape(
    Device.tablet,
    `
      inset-block-end: 19vh;
      inset-inline-start: 43vh;
      max-inline-size: calc(100vw - 50vh);
  `
  )}
  ${altNavigation(`
    position: fixed;
    inset-block-end: ${pxToRem(114)};
    inset-inline-start: ${pxToRem(16)};
    transform: none;
    inline-size: ${pxToRem(497)};
    justify-content: center;
  `)}
  ${({ theme }) => css`
    ${altNavigationZoom(
      [100, 110, 125, 150, 175, 200],
      `
        inline-size: 100%;
        inset-block-end: 0;
        inset-block-start: auto;
        inset-inline-start: 0;
        block-size: fit-content;
        background-color: ${theme.nuance.colors.primaryLight};
        padding: ${pxToRem(10)};
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: ${pxToRem(8)};
    `
    )}
  `}
`
