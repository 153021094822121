import devConfig from './config.dev'
import productionConfig from './config.production'

let masterConfig = devConfig

if (import.meta.env.PROD) {
  masterConfig = productionConfig
}

export default masterConfig
