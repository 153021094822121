import ConditionalRender from '@components/ConditionalRender'
import ErrorWidget from '@components/ErrorWidget'
import { useFullscreenLoader } from '@hooks/useFullscreenLoader'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import { useNuance } from '@hooks/useNuance'
import { useScrollUpWithBaseContainer } from '@hooks/useScrollUp'
import { useStoreContext } from '@hooks/useStoreContext'
import { equals } from '@libs/caseInsensitive'
import { parseGrapqhQLError } from '@libs/errors'
import { isNuanceCustomTab } from '@libs/nuance'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NuanceHeader } from '../NuanceHeader'
import NuanceSlider from '../NuanceSlider'
import { NuanceTabs } from '../NuanceTabs'
import { usePushToDataTrack } from './analytics'
import {
  NuanceCTA,
  NuanceTabsContentContainer,
  StyledLink,
  StyledLinkWrapper,
  StyledNuanceContent,
  StyledSpinner,
} from './styles'
import { NuanceContentProps } from './types'

export const NuanceContent: React.FC<NuanceContentProps> = ({
  isLandingPage,
  analytics,
  className,
  showCustomTabDetails = false,
  initialTabId,
  product,
}) => {
  window.history.replaceState({}, document.title)
  const store = useStoreContext()
  const { isAlternativeNavigation, zoomLevel } = useNavigationContext()
  const rtlDirection = useLanguageDirection()
  const block = useMemo(
    () => !isAlternativeNavigation || zoomLevel !== 200 || !isLandingPage,
    [isAlternativeNavigation, isLandingPage, zoomLevel]
  )
  const { isScrollUp } = useScrollUpWithBaseContainer({ block, initialValue: false })
  const pushToDataTrack = usePushToDataTrack()

  const { data, loading, error, setTabIdSelected } = useNuance(product)
  useFullscreenLoader('loading-NuanceHome', [loading])

  const tabs = useMemo(() => {
    return showCustomTabDetails ? data?.tabs : data?.tabs?.filter(tab => !isNuanceCustomTab(tab))
  }, [data?.tabs, showCustomTabDetails])
  const initialTabIndex = useMemo(
    () => Math.max(0, tabs?.findIndex(tab => equals(tab.name ?? '', initialTabId)) ?? 0),
    [initialTabId, tabs]
  )
  const [tabIndex, setTabIndex] = useState(initialTabIndex)

  useEffect(() => {
    setTabIndex(initialTabIndex)
  }, [initialTabIndex])

  const selectedTab = useMemo(() => tabs?.[tabIndex], [tabIndex, tabs])

  const tabChangeHandler = useCallback(
    (tabIndex: number) => {
      setTabIndex(tabIndex)
      pushToDataTrack(tabs?.[tabIndex])
    },
    [pushToDataTrack, tabs]
  )

  const isTabsExpanded = useMemo(() => !!tabs?.[tabIndex]?.hasShortMedia, [tabIndex, tabs])
  const showSlider = useMemo(
    () => !isAlternativeNavigation || !isTabsExpanded,
    [isAlternativeNavigation, isTabsExpanded]
  )

  useEffect(() => {
    selectedTab?.id && setTabIdSelected(selectedTab?.id)
  }, [selectedTab, setTabIdSelected])

  if (error) {
    return <ErrorWidget {...parseGrapqhQLError(error)} withWrapper />
  }

  if (!data) {
    return null
  }

  const tabsLength = (tabs?.length ?? 0) - 1

  // TODO: this is temporary, it's very ugly I'm using the analytic id to identify the accessories cta...
  const isAccessoriesCta =
    selectedTab?.cta?.link?.analyticsData?.dataElementId === 'CTA_ShopAccessories'
  const isCtaVisible = !isAccessoriesCta || (isAccessoriesCta && store.nuanceAccessoriesEnabled)

  return (
    <>
      <NuanceHeader isSticky={isScrollUp} showPrivacyPolicy />
      <StyledNuanceContent className={className}>
        <ConditionalRender
          condition={showSlider}
          render={() => (
            <NuanceSlider
              tabs={tabs}
              onSwipe={i => {
                tabChangeHandler(rtlDirection ? tabsLength - i : i)
              }}
              spiner={<StyledSpinner />}
              isTabsExpanded={isTabsExpanded}
              slideIndex={rtlDirection ? tabsLength - tabIndex : tabIndex}
              updating={loading}
            />
          )}
        />
        <StyledLinkWrapper isTabsExpanded={isTabsExpanded}>
          {isLandingPage && selectedTab?.cta && isCtaVisible && (
            <StyledLink
              to={selectedTab?.cta.link?.url ?? ''}
              data-element-id={analytics?.dataElementId}
              data-test={analytics?.dataElementId}
              data-description={analytics?.dataDescription}
              aria-label={selectedTab?.cta.ctaText}
              title={selectedTab?.cta.ctaText}
            >
              <NuanceCTA
                disabled={!selectedTab?.cta.link?.url}
                aria-hidden="true"
                aria-disabled="true"
                tabIndex={-1}
                dataAttrs={{
                  id: selectedTab?.cta.link?.analyticsData?.dataElementId ?? undefined,
                }}
              >
                {selectedTab?.cta.ctaText}
              </NuanceCTA>
            </StyledLink>
          )}
        </StyledLinkWrapper>
        <NuanceTabsContentContainer>
          <NuanceTabs
            tabs={tabs ?? []}
            isTabsExpanded={isTabsExpanded}
            tabIndex={tabIndex}
            tabChangeHandler={tabChangeHandler}
            isTabsListSticky={isScrollUp}
            product={product}
            showSlider={showSlider}
          />
        </NuanceTabsContentContainer>
      </StyledNuanceContent>
    </>
  )
}
