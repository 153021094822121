import { ZOOM_LEVEL_PREFIX, zoomLevels } from '@constants/accessibility'
import React, { useEffect } from 'react'
import {
  useLanguageDirection,
  useMediaQuery,
  useWindowResizeListener,
  useZoomLevel,
} from '../hooks/useNavigationUtils'

export interface WindowDimensions {
  height: number
  width: number
}

export interface NavigationContextData {
  landscapeNavigation: boolean
  isAlternativeNavigation: boolean
  windowDimensions: WindowDimensions
  zoomLevel: typeof zoomLevels[number]
  increase?: () => void
  decrease?: () => void
  rtlDirection: boolean
}

export const defaultNavContext: NavigationContextData = {
  landscapeNavigation: false,
  isAlternativeNavigation: false,
  windowDimensions: {
    width: 0,
    height: 0,
  },
  zoomLevel: 100,
  rtlDirection: false,
}

export const NavigationContext = React.createContext<NavigationContextData>(defaultNavContext)

interface NavigationProviderProps {
  isAlternativeNavigation?: boolean
  children: React.ReactNode
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  isAlternativeNavigation = false,
  children,
}) => {
  const isLandscape = useMediaQuery('only screen and (orientation:landscape)')
  const windowDimensions = useWindowResizeListener()
  const { zoomLevel, increase, decrease } = useZoomLevel()
  const landscapeNavigation = isLandscape || false
  const rtlDirection = useLanguageDirection()
  //Toggle right-to-left for debug purpose
  const dir = rtlDirection ? 'rtl' : 'ltr'
  useEffect(() => {
    document.documentElement.dir = dir
  }, [dir, rtlDirection])
  // Toggle class with landscape navigation
  useEffect(() => {
    document.body.classList.toggle('landscape-navigation', landscapeNavigation)
  }, [landscapeNavigation])

  // Toggle class with alternative navigation
  useEffect(() => {
    document.body.classList.toggle('alternative-navigation', isAlternativeNavigation)
  }, [isAlternativeNavigation])

  //Toggle class with zoom level
  useEffect(() => {
    const zoomLevelClass = `${ZOOM_LEVEL_PREFIX}${zoomLevel}`
    document.body.classList.add(zoomLevelClass)
    return () => {
      document.body.classList.remove(zoomLevelClass)
    }
  }, [zoomLevel])
  return (
    <NavigationContext.Provider
      value={{
        landscapeNavigation,
        isAlternativeNavigation,
        windowDimensions,
        zoomLevel,
        increase,
        decrease,
        rtlDirection,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
